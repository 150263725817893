/* src/PricingPanel.css */

.pricing-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.pricing-panel {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 1000px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}

.pricing-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.pricing-tiers {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.pricing-tier {
  flex: 1;
  min-width: 200px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.highlighted {
  border: 2px solid #4CAF50;
}

.price {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.price span {
  font-size: 16px;
  font-weight: normal;
}

.cta-button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 15px 0;
  width: 100%;
}

.cta-button:hover {
  background-color: #0056b3;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

li {
  margin: 5px 0;
}
