/* App Container */
/* src/App.css */

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #f7f7f7 25%, #ffffff 100%); /* Gradient background */
  color: #333;
  font-family: 'Roboto', sans-serif;
  padding-top: 40px;
}

.app-container {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 800px; /* Set max width to centralize content */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  text-align: center;
  margin-bottom: 40px; /* Extra space at the bottom */
}


/* Header adjustments */
.app-header {
  display: flex;
  flex-direction: row; /* Horizontally align logo and title */
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.header-nav {
  display: flex;
  gap: 10px;
}

/* Form Styles */
.form {
  display: flex;
  flex-direction: column; /* Stack form fields vertically */
  gap: 15px;
}

.form-group {
  text-align: left;
}

.form-group label {
  font-weight: 500; /* Medium weight for form labels */
  color: #333;
}

.form-group input,
.form-group select {
  width: 100%; /* Ensures inputs are the full width of their container */
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  box-sizing: border-box; /* Consistent sizing including padding and border */
}

/* Accent color for icons */
.info-icon {
  margin-left: 5px;
  color: #888;
  cursor: pointer;
}

.info-icon:hover {
  color: #b30000;
}

/* Button Styles */
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: bold;
}

.button.primary {
  background-color: #b30000;
  color: white;
}

.button.primary:hover {
  background-color: #990000;
}

.button.primary:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.button.secondary {
  background-color: #4a6fa5; /* Muted gray-blue as a secondary color */
  color: white;
}

.button.secondary:hover {
  background-color: #3a5784;
}

/* Error and Sign-In Prompt */
.error,
.sign-in-prompt {
  color: #b30000;
  font-weight: 500;
  margin-top: 10px;
}

.sign-in-prompt {
  font-size: 1rem;
}

.script-section {
  margin-top: 20px;
  text-align: left;
}

.script-section h2 {
  color: #b30000;
  font-weight: 500;
}

.script-content {
  background-color: #f1f1f1; /* Light gray for readability */
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
}
