.google-auth-button {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
  }
  
  .google-logo {
    height: 16px; /* Adjust height for smaller size */
    width: auto;
    margin-right: 8px; /* Space between logo and text */
  }
  
  /* GoogleAuth.css */
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.user-profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.sign-out-button {
  padding: 4px 8px;
  background-color: #db4437;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.google-auth-button {
  padding: 8px 16px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
