/* Header Container */
.header-container {
  background-color: #b30000; /* Red background */
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  width: 100%;
  max-width: 900px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/* App Header */
.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Logo and Title Section */
.header-logo-title {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}

.autovid-logo-large {
  height: 70px;
}

.header-title {
  font-size: 24px;
  font-weight: 500;
  color: white;
  white-space: nowrap;
}

/* Navigation Section */
.header-nav {
  display: flex;
  gap: 10px;
}

/* Button Styles */
.header-button {
  background-color: #ffffff;
  color: #b30000;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.header-button:hover {
  background-color: #e0e0e0;
}

/* Google Auth Button */
.google-auth-button {
  background-color: #ffffff;
  color: #b30000;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.google-auth-button:hover {
  background-color: #e0e0e0;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .header-container {
    padding: 5px;
  }

  .autovid-logo-large {
    height: 40px;
  }

  .header-title {
    font-size: 20px;
  }

  .header-button, .google-auth-button {
    font-size: 12px;
    padding: 0 10px;
  }
}
