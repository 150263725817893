/* src/ProgressBar.css */
.progress-bar {
    width: 100%;
    height: 8px;
    background-color: #ddd;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s;
  }
  